import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";
import Go from "../components/Go";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem 5rem;
  /* text-align: center; */
  color: white;
`;

const StyledH1 = styled.h1`
  padding: 1rem;
  font-size: 2rem;
`;

const CardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const CardWrapper = styled.div`
  margin: 1rem;
`;

const Card = styled.div`
  width: 325px;
  height: 375px;
  /* padding: 1rem; */
  background: #20232a;
  border-radius: 20px;
`;

const CardHeader = styled.div`
  height: 150px;
  padding: 2rem;
  background: #e6c886;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
`;

const CardBody = styled.div`
  height: 225px;
  /* padding: 1rem; */
`;

const PostMain = styled.div`
  height: 125px;
`;

const PostTitle = styled.div`
  padding: 1.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
    /* color: #61dafb; */
  }
`;

const PostInfo = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const PostDate = styled.div`
  padding: 1.5rem;
`;

const PostIcon = styled.div`
  padding: 1.5rem;
`;

const StyledLink = styled(Link)`
  color: white;
`;

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout title="Blog">
      <SEO pagetitle="Blog" pagedesc="ブログ" />
      <ContentWrapper>
        <Main>
          <CardList>
            {posts.map(({ node }) => (
              <CardWrapper>
                <StyledLink to={`/${node.frontmatter.slug}`}>
                  <Card>
                    <CardHeader>{node.frontmatter.category}</CardHeader>
                    <CardBody>
                      <PostMain>
                        <PostTitle>{node.frontmatter.title}</PostTitle>
                      </PostMain>
                      <PostInfo>
                        <PostDate>{node.frontmatter.date}</PostDate>
                        <PostIcon>
                          <Go />
                        </PostIcon>
                      </PostInfo>
                    </CardBody>
                  </Card>
                </StyledLink>
              </CardWrapper>
            ))}
          </CardList>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            slug
            title
            description
            date(formatString: "YYYY.MM.DD")
            category
            tags
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
