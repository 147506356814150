import * as React from "react";
import { VscArrowRight } from "react-icons/vsc";
import styled from "styled-components";

const VscArrowWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: tomato;
  border-radius: 40px;
`;

const Go = () => {
  return (
    <VscArrowWrapper>
      <VscArrowRight />
    </VscArrowWrapper>
  );
};

export default Go;
